<template>
  <!-- 提单管理 -->
  <div class="ladingBillManagementPageBox shiftingParking">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="代采业务" name="0">
        <div class="contentBox">
          <!-- 搜索筛选 -->
          <FormSearch
            :getdata="getdata"
            :form-inline="formInline"
            :form-item-arr="formItemArr"
          />
          <!-- 表格  分页 -->
          <div class="page-container-table">
            <Table
              :item-data="itemData"
              :list-data="listData"
              :loading="loading"
              :operation-button="operationButton"
            />
            <!-- 分页 -->
            <Pagination
              :params="formInline"
              :total="total"
              :in-article="listData.length"
              :get-data-list="getdata"
            />
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="商城业务" name="1">
        <el-tabs v-model="tabsActive" class="demo-tabs" @tab-click="getTabChange">
          <el-tab-pane v-for="(item,index) in tabsList" :key="index" :label="item.label" :name="item.name" />
        </el-tabs>
        <div class="contentBox">
          <!-- 搜索筛选 -->
          <FormSearch
            :getdata="getStoreData"
            :form-inline="storeFormInline"
            :form-item-arr="storeFormItemArr"
          />
          <!-- 表格  分页 -->
          <div class="page-container-table">
            <Table
              :item-data="storeItemData"
              :list-data="storeListData"
              :loading="loading"
              :operation-button="storeOperationButton"
            />
            <!-- 分页 -->
            <Pagination
              :params="storeFormInline"
              :total="total"
              :in-article="storeListData.length"
              :get-data-list="getStoreData"
            />
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { pagePlatScmTakeGoodsBill, deliveryInfoPage } from '@/api/warehousingSystem'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      cmpNameArr: [],
      editFormData: {},
      activeName: '0',
      tabsActive: '',
      tabsList: [
        { label: '全部提货单', name: '' },
        { label: '待商家审核', name: '02' },
        { label: '待出库', name: '04' },
        { label: '待提货确认', name: '05' },
        { label: '提货成功', name: '06' },
        { label: '提单审核不确认', name: '03' }
      ],
      itemData: [
        { label: '提货单编号', prop: 'deliveryNo', width: 180 },
        { label: '出库单号', prop: 'deliveryOutId', width: 180 },
        { label: '合同编号', prop: 'contractNo', width: 180 },
        { label: '货主名称', prop: 'fundsCmpName', width: 180 },
        { label: '提货公司', prop: 'custCmpName', width: 180 },
        { label: '仓库名称', prop: 'deliveryWarehouse', width: 180 },
        // { label: '提货数量', prop: 'deliveryTotalQuantity', width: 180 },
        { label: '提货数量', prop: 'deliveryTotalWeight', width: 180 },
        { label: '提货申请日期', prop: 'applyDate', width: 180 },
        { label: '出库时间', prop: 'createTime', width: 180 },
        {
          label: '状态',
          prop: 'deliveryStatus',
          width: 100,
          child: this.$store.getters.getDictionaryItem('pick_up_goods_status')
        }
      ],
      storeItemData: [
        { label: '提货单号', prop: 'takeGoodsNo', width: 180 },
        { label: '关联订单号', prop: 'orderNo', width: 180 },
        { label: '品名/材质/规格', prop: 'goodsTwoCategoryName', width: 180 },
        { label: '提货数量/单位', prop: 'applyTakeWeight', width: 180 },
        { label: '商家名称', prop: 'sellerName', width: 180 },
        { label: '买家名称', prop: 'buyerName', width: 180 },
        { label: '提货仓库', prop: 'warehouseName', width: 180 },
        { label: '预计提货日期', prop: 'prePickUpTime', width: 180 },
        { label: '状态', prop: 'state', width: 180, child: [{ dictName: '待支付', dictId: '00' }, { dictName: '支付中', dictId: '01' }, { dictName: '待审核', dictId: '02' }, { dictName: '审核不通过', dictId: '03' }, { dictName: '待出库', dictId: '04' }, { dictName: '待提货确认', dictId: '05' }, { dictName: '提货成功', dictId: '06' }, { dictName: '已取消', dictId: '07' }] }
      ],
      storeOperationButton: [
        {
          val: 'state',
          num: '04',
          bType: 'primary',
          label: '出库',
          handleEvent: this.storeWarehouse
        },
        { bType: 'primary', label: '详情', handleEvent: this.storeViewDetails }
      ],
      operationButton: [
        {
          val: 'deliveryStatus',
          num: 2,
          bType: 'primary',
          label: '出库',
          handleEvent: this.exWarehouse
        },
        { bType: 'primary', label: '详情', handleEvent: this.viewDetails }
      ],
      formItemArr: [
        {
          type: 'input',
          label: '仓库名称',
          value: 'deliveryWarehouse'
          // pLabel: 'warehouseName',
          // pValue: 'id',
          // child: this.$store.state.warehouseList
        },
        { type: 'input', label: '合同编号', value: 'contractNo' },
        { type: 'input', label: '货主名称', value: 'custName' },
        {
          type: 'input',
          label: '提货公司',
          value: 'custCmpName'
        },
        { type: 'input', label: '出库单号', value: 'outNo' },
        {
          type: 'select',
          label: '状态',
          value: 'deliveryStatus',
          pLabel: 'dictName',
          pValue: 'dictId',
          child: this.$store.getters.getDictionaryItem('pick_up_goods_status')
        }
      ],
      storeFormItemArr: [
        { type: 'input', label: '提货单号', value: 'takeGoodsNo' },
        { type: 'input', label: '订单编号', value: 'orderNo' },
        { type: 'input', label: '仓库名称', value: 'warehouseName' },
        { type: 'input', label: '商家名称', value: 'sellName' },
        { type: 'input', label: '买家名称', value: 'buyerName' }
      ],
      loading: false,
      total: 0,
      listData: [],
      storeListData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      storeFormInline: {
        pageSize: 10,
        pageNum: 1
      }
    }
  },
  watch: {
    activeName: {
      handler(newVal) {
        if (newVal === '0') {
          this.getdata()
          return
        }
        this.getStoreData()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 商城出库
    storeWarehouse({ id }) {
      this.$router.push({
        path: '/warehousingSystem/storeLadingBillExWarehouse',
        query: { id }
      })
    },
    // 获取商城的列表
    getStoreData(type) {
      if (!type) {
        this.storeFormInline.pageNum = 1
      }
      pagePlatScmTakeGoodsBill(this.storeFormInline, (res) => {
        this.storeListData = [...res.data.records]
        this.total = res.data.total
      })
    },
    // 点击生成业务的tab栏
    getTabChange() {
      this.storeFormInline.state = this.tabsActive === '0' ? '' : this.tabsActive
      this.getStoreData()
    },
    handleClick() {},
    // 查看详情
    viewDetails(row) {
      this.$router.push({
        path: '/warehousingSystem/ladingBillExWarehouseDetails',
        query: { id: row.id }
      })
    },
    storeViewDetails(row) {
      this.$router.push({
        path: '/warehousingSystem/storeMerchantBillDetails',
        query: { id: row.id }
      })
    },
    // 出库
    exWarehouse({ id }) {
      this.$router.push({
        path: '/warehousingSystem/ladingBillExWarehouse',
        query: { id }
      })
    },
    // 获取列表数据
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      deliveryInfoPage(this.formInline, (res) => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    }
  }
}
</script>

<style lang="scss">
.ladingBillManagementPageBox {
  background-color: #fff;
  .contentBox{
    padding: 0 16px;
  }
  .form-search,
  .page-container-table {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  .el-tabs__header{
    margin: 0;
    .el-tabs__nav{
      padding-left: 40px;
      .el-tabs__active-bar{
        left: 40px;
      }
    }
    .el-tabs__item{
        height: 50px;
        line-height: 50px;
      }
    .el-tabs__nav-wrap::after{
      height:1px;
    }
  }
  .demo-tabs{
    .el-tabs__nav-wrap::after{
      height:0;
    }
    .el-tabs__active-bar{
      height: 0;
    }
  }
}
</style>
